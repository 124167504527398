import React, { lazy, Suspense } from 'react';

const LineGradientFromLeft = lazy(() => import('../LineGradientFromLeft'));
const Navbar = lazy(() => import('../Navbar'));
const FooterPages = lazy(() => import('../FooterPages'));
const AnimatedTitle = lazy(() => import('../shared/AnimatedTitle'));

function Privacy() {
  return (
  <Suspense fallback={<div className='h-screen w-screen bg-[#31333F]'></div>}>
    <Navbar className="md:bg-transparent bg-[#31333F] " />
    <section className="flex flex-col justify-center text-white section-bg pt-20 md:pt-32 md:pb-20 md:mb-4
      px-2 md:px-10">
      <div className="mb-2">
        <AnimatedTitle className={'!w-full'}>
          Privacy Policy
        </AnimatedTitle>
      </div>
       <div className='text-sm md:text-base 2xl:text-xl'>          
            <div className='col-span-3 py-8'>
                <h5 className="accent-text">1.Premessa</h5>
                <p>
                La presente informativa, resa ai sensi degli artt. 13 e 14 Regolamento (UE) 679/2016 (“GDPR”) si riferisce al sito www.lmdvcapital.it (il “Sito”) ed è resa da LMDV Capital s.r.l., con sede legale in via Monte Napoleone, 5 - 20121 Milano, CF/P.IVA 12326310963, in qualità di Titolare del Trattamento dei dati personali (d’ora innanzi denominata “LMDV”). <br></br>
                Il Sito è di proprietà di LMDV, che è anche il soggetto titolare del corrispondente nome a dominio.<br></br>
                Nella presente informativa, le definizioni al singolare includono anche quelle al plurale, e viceversa.<br></br>
                In ottemperanza al GDPR, con la presente informativa intendiamo informarLa che LMDV sottoporrà a trattamento i dati personali conferiti e raccolti attraverso il Sito come descritto di seguito.
                </p>
                <br />
                <h5 className="accent-text">2. Tipi di dati trattati, finalità e basi giuridiche del trattamento</h5>
                <p>LMDV tratterà i dati personali raccolti e/o forniti attraverso il Sito secondo le modalità e per le finalità descritte nella presente sezione.</p>
                <br />
                <ul>
                    <li className='italic underline indent-6'> 
                        2.1 Dati forniti in occasione di contatti con gli interessati
                    </li>
                        <ul className='pl-12'>
                            <li>i. <span className='italic font-semibold'>Dati trattati. </span> LMDV potrà trattare i dati personali forniti dagli interessati (es. nome, cognome, dati di contatto quali indirizzo e-mail e numero di telefono, azienda di riferimento e qualsiasi altra informazione contenuta nelle richieste inviate dagli interessati) nel momento in cui questi decidono di contattare la società titolare compilando il form di contatto presente sul Sito ovvero per qualsiasi altra richiesta relativa alle attività di LMDV.</li>
                            <li>ii .<span className='italic font-semibold'>Finalità del trattamento.</span> LMDV tratterà i dati personali riferiti nel presente paragrafo al solo scopo di fornire un riscontro alle richieste presentate dagli interessati.</li>
                            <li>iii. <span className='italic font-semibold'>Basi giuridiche del trattamento. </span> La base giuridica dei trattamenti descritti nel presente paragrafo è rappresentata dal consenso degli interessati (art. 6, par. 1, lett. a) GDPR). In particolare, il fatto che un interessato decida di contattare LMDV presentando alla stessa una richiesta sarà considerato un’azione positiva inequivocabile equipollente ad un consenso scritto ai sensi dell’art. 4, n. 11) GDPR.</li>
                            <li>iv. <span className='italic font-semibold'>Necessità di conferimento dei dati.</span> Il conferimento dei dati di cui al presente paragrafo è facoltativo ma, in mancanza, LMDV non potrà dare seguito alle richieste presentate dagli interessati.</li>
                            <li>v. <span className='italic font-semibold'>Periodo di conservazione</span> I dati trattati secondo quanto riportato nel presente paragrafo saranno conservati per il periodo strettamente necessario a fornire un riscontro alle richieste degli interessati. Rimane salva la possibilità per LMDV di prolungare il predetto periodo nel caso in cui ciò sia necessario per adempiere ad obblighi di legge cui la stessa è sottoposta ovvero per tutelare un diritto di LMDV di fronte ad una autorità competente.</li>
                        </ul>
                    Il Sito non raccoglie altri dati personali, ivi inclusi i dati di navigazione (come indirizzo IP, informazioni sui dispositivi utilizzati dai visitatori del Sito, sul browser e sui sistemi operativi, etc.) in quanto non utilizza cookie, neanche tecnici.
                </ul>
                <br />
                <h5 className="accent-text">3. Modalità del trattamento</h5>
                <p>Il trattamento dei dati personali degli interessati sarà principalmente effettuato con l’ausilio di mezzi elettronici o comunque automatizzati, secondo le modalità e con gli strumenti idonei a garantire la sicurezza e la riservatezza dei dati stessi in conformità al GDPR. In particolare, saranno adottate tutte le misure tecniche, informatiche, organizzative, logistiche e procedurali di sicurezza necessarie, consentendo l’accesso alle sole persone incaricate del trattamento da parte di LMDV o dei responsabili del trattamento designati da LMDV.</p>
                <br />
                <h5 className="accent-text">4. Comunicazione e diffusione dei dati</h5>
                <p>I dati personali degli interessati trattati ai sensi della presente informativa potranno essere comunicati, ove necessario:</p>
                <ul className='pl-12'>
                    <li>
                    •	a tutti quei soggetti (ivi incluse le Pubbliche Autorità) che hanno accesso ai dati personali in forza di provvedimenti normativi o amministrativi;
                    </li>
                    <li>•	a società, consulenti o professionisti eventualmente incaricati dell’installazione, della manutenzione, dell’aggiornamento e, in generale, della gestione degli hardware e software di LMDV o di cui essa si serve per l’erogazione dei propri servizi;</li>
                    <li>•	ai dipendenti e/o collaboratori di LMDV;</li>
                    <li>•	a tutti quei soggetti pubblici e/o privati, persone fisiche e/o giuridiche (studi di consulenza legale, amministrativa e fiscale, Uffici Giudiziari, Camere di Commercio, Camere ed Uffici del Lavoro, ecc.), qualora la comunicazione risulti necessaria o funzionale al corretto adempimento degli obblighi assunti, nonché degli obblighi derivanti dalla legge.</li>
                </ul>
                I dati relativi agli interessati non saranno diffusi, se non in forma anonima ed aggregata, per finalità statistiche o di ricerca. 
                <br />
                <br />
                    
                <h5 className="accent-text">5. Trasferimento dei dati al di fuori del SEE</h5>
                <p>
                LMDV non trasferirà i dati personali degli interessati verso Paesi al di fuori dello Spazio Economico Europeo (“SEE”), che comprende oltre agli Stati membri dell’Unione Europea, Norvegia, Lichtenstein e Islanda. <br></br>
                Nel caso in cui ciò risultasse necessario per perseguire le finalità dei trattamenti descritti nella presente Informativa, LMDV garantisce che tutti gli eventuali trasferimenti dei dati al di fuori del SEE avverranno in modo tale da garantire la piena tutela dei diritti e delle libertà degli stessi. Ove, con riguardo al Paese terzo destinatario non siano state emanate decisioni di adeguatezza dalla Commissione Europea, i trasferimenti dei dati verranno effettuati adottando le garanzie previste dagli articoli 46 e seguenti del GDPR tra cui le clausole contrattuali standard approvate dalla Commissione Europea, ed una ponderata valutazione della legislazione dell’eventuale paese terzo di destinazione.
                </p>

                <br />
                <h5 className="accent-text">6. Titolare del Trattamento</h5>
                <p>LMDV, in qualità di Titolare del Trattamento dei dati personali può essere contattata ai seguenti recapiti:
                Indirizzo: LMDV Capital s.r.l. - via Monte Napoleone, 5 - 20121 Milano.
                Telefono: [•].
                E-mail: [•].
                </p>
                <br />
                <h5 className="accent-text">7. Diritti dell’interessato</h5>
                <p>Ai sensi dell’art. 13 del GDPR, LMDV La informa che Lei ha diritto di:</p>
                <ul className='pl-12'>
                    <li>•	chiedere a LMDV l’accesso ai dati personali che La riguardano e la rettifica o la cancellazione degli stessi o la limitazione del trattamento o di opporsi al loro trattamento, </li>
                    <li>•	chiedere a LMDV la portabilità dei dati che La riguardano forniti in un formato strutturato, di uso comune e leggibile da dispositivo automatico e ha il diritto di trasmettere tali dati a un altro titolare del trattamento senza impedimenti da parte di LMDV;</li>
                    <li>•	revocare il consenso in qualsiasi momento senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca;</li>
                    <li>•	proporre reclamo a un’autorità di controllo (es.: il Garante per la protezione dei dati personali).</li>
                </ul>
                I diritti di cui sopra potranno essere esercitati con richiesta rivolta senza formalità a LMDV ai contatti indicati al punto precedente.
                <br />
                <br />
                <h5 className="accent-text">8. Modifiche</h5>
                <p>LMDV si riserva il diritto di apportare modifiche alla presente informativa in qualsiasi momento, dandone idonea pubblicità agli utenti del Sito e garantendo in ogni caso una adeguata e analoga protezione dei dati personali. Al fine di visionare eventuali modifiche, Lei è invitato a consultare con regolarità la presente informativa.
                    Ad ogni modo, qualora LMDV dovesse apportare modifiche sostanziali alla presente informativa (ad es.: trattamento dei dati personali per diverse e ulteriori finalità), ne darà comunicazione agli interessati a mezzo e-mail.
                </p>
            </div>
      </div>
      <LineGradientFromLeft/>
       <div className='text-sm md:text-base 2xl:text-xl'>          
            <div className='col-span-3 py-8'>
                <h5 className="accent-text">1.Introduction</h5>
                <p>
                This notice, provided pursuant to Articles 13 and 14 of Regulation (EU) 679/2016 ("GDPR"), pertains to the website www.lmdvcapital.it (the "Site") and is issued by LMDV Capital s.r.l., headquartered at via Monte Napoleone, 5 - 20121 Milan, CF/VAT 12326310963, in its capacity as the Data Controller of personal data (hereinafter referred to as "LMDV". <br></br>
                The Site is owned by LMDV, which is also the owner of the corresponding domain name.<br></br>
                In this notice, definitions in the singular include those in the plural, and vice versa.<br></br>
                In compliance with the GDPR, we inform you that LMDV will process the personal data provided and collected through the Site as described below.
                </p>
                <br />
                <h5 className="accent-text">2. Types of Data Processed, Purposes, and Legal Bases for Processing</h5>
                <p>LMDV will process personal data collected and/or provided through the Site according to the methods and for the purposes described in this section.</p>
                <br />
                <ul>
                    <li className='italic underline indent-6'> 
                        2.1 Data Provided When Contacting Interested Parties
                    </li>
                        <ul className='pl-12'>
                            <li>i. <span className='italic font-semibold'>Data processed: </span> LMDV will process personal data referred to in this paragraph solely to respond to the requests submitted by interested parties.</li>
                            <li>ii .<span className='italic font-semibold'>Purpose of processing:</span> LMDV will process personal data referred to in this paragraph solely to respond to the requests submitted by interested parties.</li>
                            <li>iii. <span className='italic font-semibold'>Legal bases for processing: </span> The legal basis for the processing described in this paragraph is the consent of the interested parties (Art. 6, para. 1, letter a) GDPR). Specifically, the fact that an interested party decides to contact LMDV by submitting a request will be considered a positive unequivocal action equivalent to written consent under Art. 4, No. 11) GDPR.</li>
                            <li>iv. <span className='italic font-semibold'>Necessity of data provision:</span> Providing the data referred to in this paragraph is optional, but without it, LMDV will not be able to respond to the requests submitted by interested parties.</li>
                            <li>v. <span className='italic font-semibold'>Retention period:</span> The data processed as described in this paragraph will be retained for the period strictly necessary to respond to the requests of the interested parties. LMDV reserves the right to extend this period if necessary to comply with legal obligations or to protect LMDV's rights before a competent authority</li>
                        </ul>
                  The Site does not collect other personal data, including browsing data (such as IP addresses, information about devices used by Site visitors, browser and operating system information, etc.) as it does not use cookies, not even technical ones
                </ul>
                <br />
                <h5 className="accent-text">3. Processing Methods</h5>
                <p>The processing of the personal data of interested parties will primarily be carried out with the aid of electronic or automated means, according to methods and tools suitable to ensure the security and confidentiality of the data in compliance with the GDPR. Specifically, all necessary technical, IT, organizational, logistical, and procedural security measures will be adopted, allowing access only to authorized persons from LMDV or data processors designated by LMDV.</p>
                <br />
                <h5 className="accent-text">4. Data Communication and Disclosure</h5>
                <p>The personal data of the interested parties processed according to this notice may be communicated, where necessary:</p>
                <ul className='pl-12'>
                    <li>
                    •	to all subjects (including Public Authorities) who have access to personal data by virtue of regulatory or administrative provisions;
                    </li>
                    <li>•to companies, consultants, or professionals possibly in charge of installing, maintaining, updating, and generally managing LMDV's hardware and software or used by it to provide its services;</li>
                    <li>•	to LMDV employees and/or collaborators;</li>
                    <li>•	to all public and/or private entities, natural and/or legal persons (legal, administrative, and tax consulting firms, Judicial Offices, Chambers of Commerce, Chambers and Offices of Labor, etc.), if the communication is necessary or functional to the proper fulfillment of obligations undertaken, as well as obligations arising from the law.</li>
                </ul>
                The data relating to the interested parties will not be disclosed
                <br />
                <br />
                    
                <h5 className="accent-text">5. Data Transfer Outside the EEA</h5>
                <p>
                  LMDV will not transfer the personal data of interested parties to countries outside the European Economic Area ("EEA"), which includes, in addition to the EU Member States, Norway, Liechtenstein, and Iceland. <br></br>
                  If it becomes necessary to pursue the purposes of the processing described in this notice, LMDV ensures that all data transfers outside the EEA will occur in a manner that guarantees the full protection of the rights and freedoms of the interested parties. If there are no adequacy decisions issued by the European Commission regarding the recipient third country, data transfers will be carried out adopting the safeguards provided by Articles 46 et seq. of the GDPR, including the standard contractual clauses approved by the European Commission, and a thorough assessment of the legislation of the potential third country of destination.
                </p>

                <br />
                <h5 className="accent-text">6. Data Controller</h5>
                <p>LMDV, as the Data Controller of personal data, can be contacted at the following addresses: Address: LMDV Capital s.r.l. - via Monte Napoleone, 5 - 20121 Milan. Phone: [•]. Email: [•].
                </p>
                <br />
                <h5 className="accent-text">7. Rights of the Interested Party</h5>
                <p>Pursuant to Article 13 of the GDPR, LMDV informs interested parties that they have the right to</p>
                <ul className='pl-12'>
                    <li>•	request access to their personal data from LMDV and to rectify or delete it, or to restrict its processing, or to object to its processing; </li>
                    <li>•	request data portability of the data provided in a structured, commonly used, and machine-readable format and have the right to transmit such data to another data controller without impediment from LMDV;</li>
                    <li>•	withdraw consent at any time without affecting the lawfulness of processing based on consent before its withdrawal;</li>
                    <li>•	lodge a complaint with a supervisory authority (e.g., the Data Protection Authority)</li>
                </ul>
                The rights listed above can be exercised by making a request to LMDV using the contact details provided above.
                <br />
                <br />
                <h5 className="accent-text">8. Changes</h5>
                <p>LMDV reserves the right to make changes to this notice at any time, providing appropriate notice to Site users and ensuring in any case an adequate and equivalent protection of personal data. To review any changes, each interested party is invited to regularly consult this notice. <br></br>
                In any case, should LMDV make substantial changes to this notice (e.g., processing personal data for different and additional purposes), it will inform the interested parties by email.
                </p>
            </div>
      </div>
    </section>
    <FooterPages className="pt-52" />
  </Suspense>
  )
}

export default Privacy