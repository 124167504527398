import './App.css'
import { Route, Router ,  useLocation  } from "wouter"
import { lazy, Suspense } from 'react';

const Home = lazy(() => import('./components/pages/Home'));
const Team = lazy(() => import('./components/pages/Team'));
const About = lazy(() => import('./components/pages/About'));
// const Projects = lazy(() => import('./components/pages/Projects'));
const Navbar = lazy(() => import('./components/Navbar'));
const ComingSoon = lazy(() => import('./components/pages/ComingSoon'));
import Privacy from './components/pages/Privacy';
const OurPartners = lazy(() => import('./components/pages/OurPartners'));
const Wpwu = lazy(() => import('./components/pages/WhyPartnerWithUs'));
const Contacts = lazy(() => import('./components/pages/Contacts'));


// const NorthStar = lazy(() => import('./components/pages/projects/NorthStar'));
// const Boem = lazy(() => import('./components/pages/projects/Boem'));
// const MdConcierge = lazy(() => import('./components/pages/projects/MdConcierge'));
// const TripleSeaFood = lazy(() => import('./components/pages/projects/TripleSeaFood'));
// const Atiu = lazy(() => import('./components/pages/projects/Atiu'));
// const Crurated = lazy(() => import('./components/pages/projects/Crurated'));
// const Pathwater = lazy(() => import('./components/pages/projects/Pathwater'));
// const Rina = lazy(() => import('./components/pages/projects/Rina'));

function App() {
  
  const location = useLocation();

  return (
  
      <Suspense fallback={<div className='h-screen w-screen bg-[#31333F]'></div>}>
        <Navbar />
        <Router location={location} key={location.pathname}>
          <Route path="/" component={Home} />
          <Route path="/about-us" component={About} />
          <Route path="/team" component={Team} />
          <Route path="/our-partners" component={OurPartners} />
          <Route path="/why-partner-with-us" component={Wpwu} />
          <Route path="/contacts" component={Contacts} />
          <Route path="/privacy-policy" component={Privacy} />

          {/* <Route path="/projects" component={Projects} />

          <Route path="/projects/north-star" component={NorthStar} />
          <Route path="/projects/boem" component={Boem} />
          <Route path="/projects/md-concierge" component={MdConcierge} />
          <Route path="/projects/triple-sea-food" component={TripleSeaFood} />
          <Route path="/projects/atiu" component={Atiu} />
          <Route path="/projects/crurated" component={Crurated} />
          <Route path="/projects/pathwater" component={Pathwater} />
          <Route path="/projects/rina" component={Rina} /> */}
        </Router>
      </Suspense>
  
  )
}

export default App
